<template>
  <fieldset class="d-inline-flex">
    <legend>{{ typeLabel }}</legend>
    <template v-if="hasCounterParty">
      <LabeledValue label="Code" :empty="!code">
        {{ code || '--' }}
      </LabeledValue>
      <LabeledValue label="Registration" :empty="!registration">
        {{ registration || '--' }}
      </LabeledValue>
      <LabeledValue label="Class" :empty="!classCode">
        {{ classCode || '--' }}
      </LabeledValue>
      <LabeledValue v-show="isBusiness" label="Is Business">
        <MCheckIcon :value="isBusiness" />
      </LabeledValue>
    </template>
    <div v-else class="h5 text-muted mt-2 ml-1">None</div>
  </fieldset>
</template>
<script>
export default {
  name: 'VtxCounterPartyBlock',
  props: {
    type: {
      type: String,
      required: false,
      default: 'Counter Party',
    },
    code: {
      type: String,
      required: false,
      default: null,
    },
    registration: {
      type: String,
      required: false,
      default: null,
    },
    classCode: {
      type: String,
      required: false,
      default: null,
    },
    isBusiness: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    typeLabel() {
      if (!this.type) {
        return '';
      }

      return this.$tc(`counterParty.${this.type.toUpperCase()}`);
    },
    hasCounterParty() {
      return !!(this.code || this.registration || this.classCode);
    },
  },
};
</script>
