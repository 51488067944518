<template>
  <MDeleteButton
    :id="`btn-delete-test-deck-${id || testDeckId}`"
    class="mx-1"
    :variant="variant"
    :size="computedSize"
    @click="handleDelete"
  />
</template>
<script>
export default {
  name: 'MTestDeckDeleteButton',
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    testDeckId: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: 'outline',
    },
    size: {
      type: String,
      required: false,
      default: 'sm',
    },
  },
  data() {
    return {};
  },
  computed: {
    computedSize() {
      const size = `${this.size}`?.toLowerCase();
      if (!size || size === 'std') {
        return null;
      }

      return size;
    },
  },
  methods: {
    handleDelete() {
      const self = this;
      this.$swal
        .fire({
          title: 'Confirm Delete',
          text: 'Are you sure you wish to delete this test deck?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        })
        .then((res) => {
          if (res.isConfirmed) {
            this.$store
              .dispatch('testing/deleteTestDeck', self.testDeckId)
              .then(() => {
                self.$emit('deleted', self.testDeckId);
              })
              .catch((err) => {
                this.$log.error(err);
                this.$swal.fire({
                  title: 'Error',
                  text: 'The test deck could not be deleted',
                  icon: 'error',
                });
              });
          }
        });
    },
  },
};
</script>
