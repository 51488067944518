<template>
  <MRunButton
    :id="`btn-execute-test-deck-${id || testDeckId}`"
    :variant="variant"
    :size="computedSize"
    title="Run Test Deck"
    @click="(e) => $emit('click', e)"
  />
</template>
<script>
import { MRunButton } from '@/components/Buttons';
export default {
  name: 'MTestDeckRunButton',
  components: {
    MRunButton,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    testDeckId: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: 'outline',
    },
    size: {
      type: String,
      required: false,
      default: 'sm',
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    computedSize() {
      const size = `${this.size}`?.toLowerCase();
      if (!size || size === 'std') {
        return null;
      }

      return size;
    },
  },
  methods: {
    showTestDeckRunModal() {
      this.showModal = true;
    },
  },
};
</script>
